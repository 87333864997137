<template>
  <v-card height="100%">
    <v-form @submit.prevent="handleRegister()" ref="form" v-model="valid">
      <v-card-title class="text-h6">Ik wil een account aanvragen</v-card-title>
      <v-card-text>
        <v-alert
            :value="!!alertMessage.length"
            class="mb-10"
            transition="fade-transition"
            :type="alertType"
        >
          {{ alertMessage }}
        </v-alert>
        <VTextField
            :rules="[(v) => !!v || 'Naam is verplicht', () => serverError('name')]"
            label="Naam"
            v-model="form.name"
            tabindex="10"
        />
        <VTextField
            :rules="[(v) => !!v || 'E-Mail is verplicht', () => serverError('email')]"
            label="E-Mail"
            v-model="form.email"
            tabindex="11"
        />
      </v-card-text>
      <v-card-actions>
        <VSpacer/>
        <v-btn tabindex="12" type="submit" color="primary" :loading="isLoading">Registreren</v-btn>
      </v-card-actions>
    </v-form>
  </v-card>
</template>

<script>
import { register } from '@/api/endpoints/authorisation/register.js';
import BaseForm from './../BaseForm';

export default {
  name: 'RegisterCard',
  extends: BaseForm,
  data() {
    return {
      alertType: 'info',
      alertMessage: '',
      isLoading: false,
      form: {
        name: '',
        email: '',
      },
    };
  },
  methods: {
    handleRegister() {
      this.validate();
      if (!this.valid) {
        return;
      }
      this.isLoading = true;

      register(this.form.email, this.form.name)
          .then(() => {
            this.alertType = 'success';
            this.alertMessage = 'Er is een account aangemaakt, controleer je e-mail om een wachtwoord te kiezen zodat je vervolgens kunt inloggen.';
          })
          .catch(() => {
            this.alertType = 'error';
            this.alertMessage = 'De ingevulde gegevens kloppen niet.';
          })
          .finally(() => {
            this.isLoading = false;
            this.validate();
          });
    },
  },
};
</script>

<style scoped>

</style>
