<template>
  <v-card height="100%">
    <v-form @submit.prevent="handleLogin()" ref="form" v-model="valid">
      <v-card-title class="text-h6">Ik wil inloggen</v-card-title>
      <v-card-text>
        <v-alert
            :value="!!alertMessage.length"
            class="mb-10"
            transition="fade-transition"
            type="error"
        >
          {{ alertMessage }}
        </v-alert>
        <VTextField
            :rules="[(v) => !!v || 'E-Mail is verplicht']"
            label="E-Mail"
            v-model="form.email"
            tabindex="1"
        />
        <VTextField
            :append-icon="showPassword ? 'fa-eye-slash' : 'fa-eye'"
            :rules="[(v) => !!v || 'Wachtwoord is verplicht']"
            :type="showPassword ? 'text' : 'password'"
            @click:append="showPassword = !showPassword"
            label="Wachtwoord"
            v-model="form.password"
            tabindex="2"
        />
      </v-card-text>
      <v-card-actions>
        <VSpacer/>
        <v-btn @click="handlePasswordForgot" tabindex="4" color="primary" text>Wachtwoord vergeten</v-btn>
        <v-btn tabindex="3" type="submit" color="primary" :loading="isLoading">Inloggen</v-btn>
      </v-card-actions>
    </v-form>

    <v-overlay v-model="isRedirecting" class="text-center">
      <VProgressCircular indeterminate size="64"/>
      <div class="mt-5">Je bent ingelogd, we sturen je nu door.</div>
    </v-overlay>
  </v-card>
</template>

<script>
import loginRequest from './../../api/endpoints/authorisation/login.js';
import dispenseRequest from './../../api/endpoints/authorisation/dispense.js';
import { mapGetters } from 'vuex';
import { getRateLimitMinutes } from '@/api/utils/response.js';

export default {
  name: 'LoginCard',
  data() {
    return {
      alertMessage: '',
      isLoading: false,
      showPassword: false,
      valid: false,
      form: {
        email: '',
        password: '',
      },
      isRedirecting: false,
    };
  },
  computed: {
    ...mapGetters({
      findError: 'error/find',
    }),
  },
  methods: {
    handlePasswordForgot() {
      this.$router.push({
        name: 'password.forgotten',
        params: {
          email: this.form.email,
        },
      });
    },
    handleLogin() {
      this.$refs.form.validate();
      if (!this.valid) {
        return;
      }
      this.isLoading = true;
      loginRequest(this.form.email, this.form.password)
          .then(res => {
            this.isRedirecting = true;
            this.redirectToAuthDispense(res.data.data.token, this.form.email);
          })
          .catch(err => {
            const response = err.response;
            const status = response.status;

            if (status === 429) {
              this.alertMessage =
                  `Je hebt te veel foutieve inlog pogingen gedaan.
                             Probeer het over ${getRateLimitMinutes(response, 15)} minuten opnieuw`;
            } else {
              this.alertMessage = this.findError('email');
            }
            this.$refs.form.validate();
          })
          .finally(() => this.isLoading = false);
    },
    async redirectToAuthDispense(token, email) {
      try {
        const res = await dispenseRequest(email, token);

        this.isRedirecting = true;
        await this.$store.dispatch('authorisation/setToken', res.data.data.token);
      } catch(err) {
        this.errorMessage = err.message;
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>
