<template>
  <v-row justify-md="center" align="stretch" class="py-12">
    <v-col md="4">
      <LoginCard/>
    </v-col>
    <v-col md="4">
      <RegisterCard/>
    </v-col>
  </v-row>
</template>

<script>
import RegisterCard from '@/components/Authorisation/RegisterCard.vue';
import LoginCard from '@/components/Authorisation/LoginCard.vue';

export default {
  name: 'Login',
  components: {
    RegisterCard,
    LoginCard,
  },
  created() {
    this.$hero.$emit('setCanSearch', false);
    this.$hero.$emit('setTitle', 'Inloggen');
    this.$hero.$emit('setBreadcrumbs', []);
  },
};
</script>

<style scoped>

</style>
